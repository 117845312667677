<template>
  <div>
    <a-card title="分拣报告">
      <a-row :gutter="[12, 12]">
        <a-col :span="24" style="width: 256px">
          <a-range-picker v-model="searchDate" @change="search" />
        </a-col>
        <a-col :span="24" style="width: 256px">
          <a-select v-model="searchForm.status" placeholder="状态" allowClear style="width: 100%" @change="search">
            <a-select-option key="pending" value="pending">进行中</a-select-option>
            <a-select-option key="completed" value="completed">已完成</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px">
          <a-button type="primary" icon="search" :loading="dataLoading" @click="search" style="width: 100%">查询</a-button>
        </a-col>
        <a-col :span="24" style="width: 280px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
          </a-button-group>
        </a-col>

        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :dataSource="dataItems"
            :loading="dataLoading"
            :pagination="pagination"
            :scroll="{ x: true }"
            @change="changeTable"
          >
            <template slot="status" slot-scope="value, item">
              <a-badge v-if="item.status === 'completed'" status="success" text="已完成" />
              <a-badge v-else status="processing" text="进行中" />
            </template>
            <template slot="action" slot-scope="value, item">
              <table-action :item="item" @destroy="destroy" @update="update" />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { sortingReportList, sortingReportTemplate, sortingReportImport } from "@/apis/sorting";
import { replaceItem, removeItem } from "@/utils/functions";
import { exportExcel } from "@/utils/excel";

export default {
  components: {
    TableAction: () => import("./TableAction"),
  },
  data() {
    return {
      searchForm: { search: "", page: 1, ordering: undefined },
      searchDate: [undefined, undefined],
      pagination: { current: 1, total: 0, pageSize: 16 },
      dataLoading: true,
      importLoading: false,

      // Table
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "状态",
          dataIndex: "status",
          width: 160,
          scopedSlots: { customRender: "status" },
        },
        {
          title: "创建人",
          dataIndex: "creator",
          width: 180,
          customRender: (_, item) => item.creator_item?.name,
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          width: 180,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 240,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      dataItems: [],
    };
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    list() {
      const searchForm = {
        ...this.searchForm,
        start_date: this.searchDate[0] ? this.searchDate[0].clone().format("YYYY-MM-DD") : undefined,
        end_date: this.searchDate[1] ? this.searchDate[1].clone().add(1, "days").format("YYYY-MM-DD") : undefined,
      };

      this.dataLoading = true;
      sortingReportList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.dataItems = data.results;
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    update(item) {
      this.dataItems = replaceItem(this.dataItems, item);
    },
    destroy(item) {
      this.dataItems = removeItem(this.dataItems, item);
    },
    changeTable(pagination, _filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    downloadTemplate() {
      sortingReportTemplate().then((response) => {
        exportExcel(response, "分拣报告导入模板");
      });
    },
    importExcel(item) {
      const data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      sortingReportImport(data)
        .then(() => {
          this.$message.success("导入成功");
          this.list();
        })
        .finally(() => {
          this.importLoading = false;
        });
    },
  },
  mounted() {
    this.list();
  },
};
</script>

<style scoped></style>
